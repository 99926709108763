<template>
  <Detail />
</template>

<script>
import Detail from "@/views/retailer/list-of-products/detail.vue";
export default {
  components: {
    Detail,
  },
};
</script>

<style lang="scss" scoped></style>
